nav .nav-wrapper {
    max-width: 1000px;
    padding: 0 16px;
    margin: 0 auto;
  }
  
  section {
    padding-top: 0;
  }
  
  section#footer {
    padding: 24px 0;
  }
  
  h1 {
    font-weight: 300;
  }
  
  p {
    font-size: 16px;
  }
  
  #content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  #content .container {
    min-height: 90vh;
  }
  
  #features .material-icons {
    font-size: 50px;
  }

  /* @media (min-width: 992px) */
  .content {
    max-width: 960px;
  }
  
  
  .flex-dir-col {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .jc-center {
    justify-content: center;
  }
  .jc-space-between {
    justify-content: space-between;
  }
  
  .m-r-16 {
    margin-right: 16px;
  }

  .m-r-8 {
    margin-right: 8px;
  }

  .store-btn {
    display: inline-block;
  }
  
  .btn, .btn-large{
    display: flex;
    background-color: #005bbc;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    font-weight: 600;
    font-size: 1.25rem;
    padding: 0 1.5rem;
  }
  
  .btn:hover, .btn-large:hover{
    background-color: #3c93f1;
  }
  
  .teal.lighten-1{
    background-color: #925FEB !important;
  }